import React, { useEffect, useState } from 'react'; 
import { Box, Typography, IconButton } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import banner from '../../assets/images/topbanner.jpg';
import overlay from '../../assets/images/topbanneroverlay.png';
import PyramidCanvas from '../pyramid/Pyramid';
import { styled, keyframes } from '@mui/material/styles';
import { play, pause } from '../../utils/audioManager'; 

const scaleUpDown = keyframes`
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.2);
  }
  40% {
    transform: scale(0.8);
  }
  60% {
    transform: scale(1.2);
  }
  80% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
`;

const AnimatedIconButton = styled(IconButton)(({ theme, isPlaying, opacity }) => ({
  position: 'absolute',
  left: '-60px',
  color: 'white',
  backgroundColor: 'rgba(0, 0, 0, 0.9)',
  borderRadius: '50%',
  padding: '10px',
  border: isPlaying ? '1px solid #c4923b' : '1px solid white',
  opacity: opacity,
  transition: 'opacity 0.5s ease-out',
  zIndex: 2,
  animation: isPlaying ? `${scaleUpDown} 1s infinite` : 'none',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    color: 'black',
  },
}));

const Topbanner = ({ isPlaying, togglePlayPause }) => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isPlaying) {
      play();
    } else {
      pause();
    }
  }, [isPlaying]);

  const opacity = Math.max(1 - scrollY / 300, 0);
  const overlayOpacity = Math.max(0.8 - scrollY / 2000, 0);

  return (
    <Box
      sx={{
        position: 'relative',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundImage: `url(${banner})`,
          backgroundSize: 'cover',
          backgroundAttachment: 'fixed',
          backgroundPosition: 'center',
          zIndex: 0,
        }}
      />

      <Box
        sx={{
          position: 'fixed',
          top: -200,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundImage: `url(${overlay})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          zIndex: 1,
          transform: `translateY(${scrollY * 0.2}px)`,
          clipPath: 'inset(0 0 0 0)',
          opacity: overlayOpacity,
          transition: 'opacity 0.1s ease-out',
        }}
      />

      <Box
        sx={{
          position: 'absolute',
          top: '60%',
          left: { xs: '55%', sm: '53%', md: '51%' },
          transform: 'translate(-50%, 0)',
          display: 'flex',
          alignItems: 'center',
          zIndex: 2,
        }}
      >
        <AnimatedIconButton
          onClick={togglePlayPause}
          isPlaying={isPlaying}
          opacity={opacity}
        >
          {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
        </AnimatedIconButton>

        <Box
          sx={{
            padding: '20px',
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
            border: '1px solid white',
            borderRadius: '0px',
            opacity: opacity,
            transition: 'opacity 0.5s ease-out',
            textAlign: 'center',
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontFamily: 'PT Sans, sans-serif',
              fontSize: { xs: '0.58rem', sm: '0.7rem', md: '1rem' },
              color: '#ffffff',
              textAlign: 'center',
            }}
          >
            crafted brilliance for every industry.
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          position: 'absolute',
          top: '40%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 2,
          opacity: opacity,
          transition: 'opacity 0.1s ease-out',
        }}
      >
        <PyramidCanvas />
      </Box>
    </Box>
  );
};

export default Topbanner;
