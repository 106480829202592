import React, { useRef, useState, useEffect } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';

const Pyramid = () => {
  const meshRef = useRef(null);
  const [rotationOrder] = useState(['up', 'right', 'down', 'left']);
  const [currentDirection, setCurrentDirection] = useState(0); 
  const [isPaused, setIsPaused] = useState(false);
  const [pressStart, setPressStart] = useState(null);

  const longPressThreshold = 250;

  const handlePointerDown = () => {
    setPressStart(Date.now());
  };

  const handlePointerUp = () => {
    const pressDuration = Date.now() - pressStart; 

    if (pressDuration < longPressThreshold) {
      setCurrentDirection((prev) => (prev + 1) % rotationOrder.length);
    }

    setIsPaused(false);
    setPressStart(null);
  };

  const handlePointerLeave = () => {
    if (pressStart) {
      setIsPaused(false);
      setPressStart(null);
    }
  };

  const handlePointerEnter = () => {
    if (isPaused && pressStart) {
      setIsPaused(false);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (isPaused) {
        setIsPaused(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isPaused]);

  useFrame(() => {
    if (meshRef.current) {
      if (pressStart) {
        const pressDuration = Date.now() - pressStart;
        if (pressDuration >= longPressThreshold) {
          setIsPaused(true);
        }
      }
      if (!isPaused) {
        const direction = rotationOrder[currentDirection];
        switch (direction) {
          case 'up':
            meshRef.current.rotation.x += 0.01;
            break;
          case 'right':
            meshRef.current.rotation.y += 0.01;
            break;
          case 'down':
            meshRef.current.rotation.x -= 0.01;
            break;
          case 'left':
            meshRef.current.rotation.y -= 0.01;
            break;
          default:
            break;
        }
      }
    }
  });

  return (
    <mesh
      ref={meshRef}
      position={[0, 0, 0]}
      onPointerDown={handlePointerDown}
      onPointerUp={handlePointerUp}
      onPointerLeave={handlePointerLeave}
      onPointerEnter={handlePointerEnter}
    >
      <coneGeometry args={[1, 2, 4]} />
      <meshStandardMaterial color="white" />
    </mesh>
  );
};

const PyramidCanvas = () => (
  <Canvas
    style={{ height: '300px', width: '300px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
    camera={{ position: [0, 5, 5], fov: 23 }}
  >
    <ambientLight intensity={0.5} />
    <directionalLight position={[-5, 5, 5]} intensity={2} /> 
    <Pyramid />
  </Canvas>
);

export default PyramidCanvas;
