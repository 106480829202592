import React from 'react';
import { Box, Typography } from '@mui/material';
import Icon1 from '../../assets/images/mid4/mid4a.webp'; 
import Icon2 from '../../assets/images/mid4/mid4b.webp';
import Icon3 from '../../assets/images/mid4/mid4c.webp';
import Icon4 from '../../assets/images/mid4/mid4d.webp';
import Icon5 from '../../assets/images/mid4/mid4e.webp';

const iconsAndText = [
  { icon: Icon1, text: 'trusted development' },
  { icon: Icon2, text: 'service maintenance' },
  { icon: Icon3, text: 'quality guaranteed' },
  { icon: Icon4, text: 'creative designs' },
  { icon: Icon5, text: 'best rate available' },
];

const MidSection = ({ visible }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: { xs: '600px', sm: '400px', md: '300px' },
        padding: '20px',
        boxSizing: 'border-box',
        fontFamily: 'Press Start 2P, sans-serif',
        zIndex: 4, 
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        {iconsAndText.map((item, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              width: { xs: '50%', sm: '30%', md: '15%' }, 
              padding: '10px',
              transform: visible ? 'translateY(0)' : 'translateY(30px)',
              transition: 'transform 0.5s ease-out',
              animation: visible ? `slideUp 0.5s ease-out ${index * 0.2}s forwards` : 'none',
            }}
          >
            <img
              src={item.icon}
              alt={`Icon ${index + 1}`}
              style={{ 
                width: '50px', 
                height: '50px', 
                marginBottom: '10px',
                animation: `float 3s ease-in-out infinite`, 
                animationDelay: `${index * 0.5}s`,
              }}
            />
            <Typography
              variant="h4" 
              sx={{ fontSize: '12px', textAlign: 'center', color: 'black', fontFamily: 'Press Start 2P, sans-serif' }}
            >
              {item.text}
            </Typography>
          </Box>
        ))}
      </Box>

      <style>
        {`
          @keyframes slideUp {
            from {
              transform: translateY(20px);
              opacity: 0.25;
            }
            to {
              transform: translateY(0);
              opacity: 1;
            }
          }

          @keyframes float {
            0% { transform: translateY(0); }
            50% { transform: translateY(-10px); }
            100% { transform: translateY(0); }
          }

          @media (max-width: 600px) {
            .mid-section-container {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              grid-template-rows: repeat(2, auto) 1fr;
              gap: 20px;
            }
          }
        `}
      </style>
    </Box>
  );
}

export default MidSection;
