import React from 'react';
import { Box, Typography } from '@mui/material';
import logo from '../../assets/images/logo.png';

const currentYear = new Date().getFullYear();

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: 'black',
        color: '#fff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        zIndex: 2, 
        position: 'relative', 
      }}
    >
      <Box sx={{ marginBottom: '20px' }}>
        <img src={logo} alt="Logo" style={{ maxHeight: '40px' }} />
      </Box>

      <Box
        sx={{
          width: '80%',
          borderBottom: '1px solid #c4923b',
          marginBottom: '20px',
        }}
      />

      <Typography
        variant="body2"
        sx={{
          fontFamily: 'PT Sans, sans-serif',
          fontSize: '16px',
          textAlign: 'center',
        }}
      >
        © NEXTRIO ENTERPRISE {currentYear}
      </Typography>
    </Box>
  );
};

export default Footer;
