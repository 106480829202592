import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Box } from '@mui/material';
import logo from '../../assets/images/logo.png';
import logo2 from '../../assets/images/logo2.png'; 

function CustomAppBar() {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <AppBar
      position="fixed" 
      elevation={0} 
      sx={{
        backgroundColor: scrolled ? 'rgba(255,255,255,0.9)' : 'rgba(0,0,0,0.5)',
        height: scrolled ? '70px' : '80px',
        transition: 'background-color 0.3s ease, height 0.3s ease',
        zIndex: 5,
        paddingTop: scrolled ? '0px':'8px'
      }}
    >
      <Toolbar disableGutters>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <img 
            src={scrolled ? logo2 : logo} 
            alt="Logo" 
            className={`logo ${scrolled ? 'flipped' : ''}`}
            style={{ 
              paddingTop: '25px',
              maxHeight: '80px',
              height: 'auto', 
              width: 'auto',
              transition: 'transform 0.6s ease'
            }} 
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default CustomAppBar;
