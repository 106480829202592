import React, { useState, useEffect } from 'react';
import './App.css';
import Appbar from './components/appbar/Appbar';
import Topbanner from './components/topbanner/Topbanner';
import MidSection from './components/midsection/MidSection';
import About from './components/about/About';
import Footer from './components/footer/Footer';
import { IconButton } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { styled, keyframes } from '@mui/material/styles';
import { play, pause, getIsPlaying } from './utils/audioManager'; 

const scaleUpDown = keyframes`
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.2);
  }
  40% {
    transform: scale(0.8);
  }
  60% {
    transform: scale(1.2);
  }
  80% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
`;

const FloatingButton = styled(IconButton)(({ theme, isPlaying }) => ({
  height: '40px',
  width: '40px',
  position: 'fixed',
  bottom: theme.spacing(3),
  left: theme.spacing(3),
  backgroundColor: 'rgba(0, 0, 0, 0.9)',
  color: 'white',
  border: isPlaying ? '1px solid #c4923b' : '1px solid white',
  borderRadius: '50%',
  padding: theme.spacing(2),
  zIndex: 1000,
  transition: 'opacity 0.5s ease-out', 
  opacity: 0,
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    color: 'black',
  },
  animation: isPlaying ? `${scaleUpDown} 1s infinite` : 'none',
}));

function App() {
  const [midSectionVisible, setMidSectionVisible] = useState(false);
  const [fabVisible, setFabVisible] = useState(false);
  const [isPlaying, setIsPlaying] = useState(getIsPlaying());

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setMidSectionVisible(scrollY > 100);
      setFabVisible(scrollY > 200);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isPlaying) {
      play();
    } else {
      pause();
    }
  }, [isPlaying]);

  const handleFabClick = () => {
    setIsPlaying(prev => !prev);
  };

  return (
    <div className="App" style={{ position: 'relative' }}>
      <Appbar />
      <div>
        <Topbanner isPlaying={isPlaying} togglePlayPause={handleFabClick} />
      </div>
      <div>
        <MidSection visible={midSectionVisible} />
      </div>
      <About />
      <Footer />
      <FloatingButton
        onClick={handleFabClick}
        isPlaying={isPlaying}
        style={{ opacity: fabVisible ? 1 : 0 }}
      >
        {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
      </FloatingButton>
    </div>
  );
}

export default App;
