import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import TopbannerImage from '../../assets/images/topbanner2.png';
import useIntersectionObserver from '../../hooks/useIntersectionObserver'; 

const fullText = `
   Welcome to our company, where we turn your digital dreams into reality. We specialize in mobile app development, creating robust and intuitive applications that deliver a seamless user experience across all devices.

   Our expertise extends to web applications, where we build powerful and scalable solutions tailored to your business needs. Our team is dedicated to crafting engaging and functional web apps that drive results and enhance user satisfaction.

   In addition, we offer top-notch design services, focusing on delivering aesthetically pleasing and user-centric designs that stand out in the crowded digital landscape. Our design philosophy revolves around blending creativity with functionality to create stunning visuals that capture your brand's essence.

   Quality is at the core of everything we do. We are committed to delivering exceptional results by adhering to the highest standards and employing best practices in all our projects. Our goal is to exceed your expectations and ensure your success in the digital world.`;

const typingSpeed = 15;

const About = () => {
  const [displayedText, setDisplayedText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });

  useEffect(() => {
    if (isVisible && currentIndex < fullText.length) {
      const timeoutId = setTimeout(() => {
        setDisplayedText((prevText) => prevText + fullText[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, typingSpeed);
      return () => clearTimeout(timeoutId);
    }
  }, [currentIndex, isVisible]);

  return (
    <Box
      ref={ref}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        padding: '20px',
        backgroundImage: `url(${TopbannerImage})`,
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center',
        color: 'white',
        fontFamily: 'Press Start 2P, sans-serif',
        textAlign: 'center',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.9)',
          padding: '20px',
          borderRadius: '8px',
          maxWidth: '800px',
          position: 'relative',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          zindex: '9999',
        }}
      >
        <Typography 
          variant="h2" 
          sx={{ 
            fontSize: '24px', 
            marginBottom: '20px', 
            fontWeight: 'bold', 
            color: 'white',
            whiteSpace: 'pre-wrap', 
            overflow: 'hidden',
            textAlign: 'center'
          }}
        >
          About Us
        </Typography>
        
        <Typography 
          variant="h4" 
          sx={{ 
            fontSize: '14px', 
            lineHeight: '1.6', 
            color: 'white',
            whiteSpace: 'pre-wrap', 
            overflow: 'hidden',
            width: '100%',
            minHeight: '100px', 
            textAlign: 'left',
            position: 'relative',
          }}
        >
          {displayedText}
          <span className="cursor">|</span>
        </Typography>
      </Box>

      <style>
        {`
          @keyframes blink {
            0% { opacity: 1; }
            50% { opacity: 0; }
            100% { opacity: 1; }
          }
          .cursor {
            display: inline-block;
            animation: blink 1s step-start infinite;
          }
        `}
      </style>
    </Box>
  );
}

export default About;
