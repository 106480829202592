const audio = new Audio(require('../assets/audio/bg.mp3'));

export const play = () => {
  audio.loop = true;
  audio.play().catch(error => console.error('Audio play error:', error));
};

export const pause = () => {
  audio.pause();
};

export const getIsPlaying = () => !audio.paused;
